import {OfferListSubType} from "../types/OfferListSubType";

export const getFloorSubFilterText = (offerListSubFilter: string | undefined) => {
    switch (offerListSubFilter) {
        case OfferListSubType.FLOOR_GROUND_WITH_GARDEN: {
            return "z ogródkiem";
        }
        case OfferListSubType.FLOOR_GROUND: {
            return "na parterze";
        }
        case OfferListSubType.LAST_FLOOR: {
            return "ostatnie piętro";
        }
        default: {
            return "";
        }
    }
};
