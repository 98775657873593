import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes, IAction} from "@pg-mono/request-state";

import {fetchApplicationOfferDetailById} from "../api/fetch_application_offer_detail_by_id";
import {IApplicationOffer} from "../types/IApplicationOffer";

const APPLICATION_OFFER_DETAIL_PREFIX = "desktop/application/offer_detail/fetch";
export const fetchApplicationOfferDetailTypes = createRequestActionTypes(APPLICATION_OFFER_DETAIL_PREFIX);

export const fetchApplicationOfferDetail =
    (offerId: number, applicationId: string) =>
    (dispatch: Dispatch): Promise<IApplicationOffer | void> => {
        dispatch({type: fetchApplicationOfferDetailTypes.start, id: applicationId});

        return fetchApplicationOfferDetailById(offerId)
            .then((json) => {
                dispatch({type: fetchApplicationOfferDetailTypes.success, id: applicationId, result: json});
                return json;
            })
            .catch(
                catch404(() => {
                    dispatch({type: fetchApplicationOfferDetailTypes.error, id: applicationId});
                })
            );
    };

export const resetApplicationOfferDetail = (): IAction => ({type: fetchApplicationOfferDetailTypes.reset});
