import {Dispatch} from "redux";

import {IFetchContext} from "@pg-mono/data-fetcher";
import {isEmpty, isEqual} from "@pg-mono/nodash";
import {appendQueryString, getRequest} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";
import {apiV2Link, apiV2ListLink, Scenario} from "@pg-mono/rp-api-routes";

import {IRPStore} from "../../../app/rp_reducer";
import {IRPRequestMeta} from "../../../app/rp_request_meta";

export interface IRegionAncestor {
    id: number;
    slug: string;
    name: string;
}

interface IRegionStats {
    properties_all_ranges_price_m2_min: number;
    active_1_percentile_price_m2: number | null;
    active_2_percentile_price_m2: number | null;
    active_3_percentile_price_m2: number | null;
    region_type_city: IRegionAncestor | null;
    region_type_county: IRegionAncestor | null;
    region_type_district: IRegionAncestor | null;
    region_type_housing_estate: IRegionAncestor | null;
    region_type_town: IRegionAncestor | null;
    region_type_voivodeship: IRegionAncestor | null;
    offers_count_for_sale: number;
    promotions_count_total: number;
    average_price_m2: number;
    // all properties
    properties_all_count_for_sale: number;
    properties_all_rooms_ranges: Record<number, number>;
    // promotions
    promotions_count_discounts: number;
    promotions_count_promotions: number;
    promotions_count_last_minutes: number;
    promotions_count_presales: number;
    promotions_count_open_days: number;
    // flats
    offers_flats_count_for_sale: number;
    properties_flats_count_cheap: number;
    properties_flats_count_for_sale: number;
    properties_flats_count_luxury: number;
    properties_flats_ranges_area_min: number;
    properties_flats_ranges_area_max: number;
    properties_flats_rooms_ranges: Record<number, number>;
    properties_flats_ranges_price_min: number;
    properties_flats_count_ready: number;
    properties_flats_count_ready_in_12m: number;
    properties_flats_count_ready_in_3m: number;
    properties_flats_count_ready_in_6m: number;
    properties_flats_ground_floor: number;
    properties_flats_ground_floor_with_garden: number;
    properties_flats_low_floor: number;
    properties_flats_high_floor: number;
    properties_flats_last_floor: number;
    properties_flats_last_floor_with_terrace: number;
    properties_flats_latest_construction_date: string;
    // houses
    offers_houses_count_for_sale: number;
    properties_houses_rooms_ranges: Record<number, number>;
    properties_houses_ranges_price_max: number;
    properties_houses_count_for_sale: number;
    properties_houses_count_luxury: number;
    properties_houses_count_cheap: number;
    properties_houses_detached_count: number;
    properties_houses_semi_detached_count: number;
    properties_houses_serial_count: number;
    properties_houses_ranges_area_min: number;
    properties_houses_ranges_area_max: number;
    properties_houses_ranges_price_min: number;
    properties_houses_count_ready: number;
    properties_houses_count_ready_in_12m: number;
    properties_houses_count_ready_in_3m: number;
    properties_houses_count_ready_in_6m: number;
    properties_houses_top_vendors: {
        id: number;
        name: string;
        count: number;
    }[];
    properties_houses_latest_construction_date?: string;
    properties_houses_additional_areas_attic?: number;
    // commercials
    offers_commercials_count_for_sale: number;
    properties_commercials_count_for_sale: number;
    properties_commercials_count_cheap: number;
    properties_commercial_count_ready: number;
    properties_commercials_rooms_ranges: Record<number, number>;
    properties_commercials_ranges_price_min: number;
    properties_commercials_ranges_price_m2_avg: number;
    properties_commercials_count_ready: number; // TODO - czy to istnieje? - chyba jest literówka
    properties_commercials_count_ready_in_12m: number;
    properties_commercials_count_ready_in_3m: number;
    properties_commercials_count_ready_in_6m: number;
    properties_commercials_latest_construction_date: string;
    properties_commercials_ranges_area_min: number;
    properties_commercials_ranges_area_max: number;
    // studios
    offers_studios_count_for_sale: number;
    properties_studios_count_for_sale: number;
    properties_studios_ranges_price_m2_avg: number;
    properties_studios_ranges_price_min: number;
    properties_studios_count_ready: number;
    properties_studios_count_ready_in_12m: number;
    properties_studios_count_ready_in_3m: number;
    properties_studios_count_ready_in_6m: number;
    properties_studios_ground_floor: number;
    properties_studios_ground_floor_with_garden: number;
    properties_studios_high_floor: number;
    properties_studios_last_floor: number;
    properties_studios_last_floor_with_terrace: number;
    properties_studios_low_floor: number;
    vendors_count_for_sale: number;
}

export interface ISelectedRegion {
    full_name: string;
    full_name_reverted: string;
    id: number;
    included_location: {
        description: string;
    } | null;
    name: string;
    name_declension_about: string;
    name_declension_what: string;
    name_declension_where: string;
    parent: {
        id: number;
        name: string;
    } | null;
    is_duplicate: boolean;
    short_name: string;
    short_name_reverted: string;
    slug: string;
    stats: IRegionStats;
    type: number;
}

interface IMultiSelectedRegion {
    full_name: string;
    full_name_reverted: string;
    id: number;
    name: string;
}

interface IRegionListResponse {
    results: ISelectedRegion[];
}

const FETCH_SELECTED_REGION_DATA = "offer_list/FETCH_SELECTED_REGION_DATA";
export const fetchSelectedRegionDataTypes = createRequestActionTypes(FETCH_SELECTED_REGION_DATA);
export const fetchSelectedRegionBySlug =
    (meta: IRPRequestMeta, slug: string) =>
    (dispatch: Dispatch): Promise<ISelectedRegion | null> => {
        const regionListApiLink = apiV2ListLink.region.list(Scenario.SEARCH_REGION_DETAIL);
        const url = appendQueryString(regionListApiLink, {slug});
        dispatch({type: fetchSelectedRegionDataTypes.start, latestQuery: [slug]});
        return getRequest(meta, url).then((response: IRegionListResponse) => {
            const regions = response.results;
            if (!isEmpty(regions)) {
                dispatch({type: fetchSelectedRegionDataTypes.success, result: regions});
                return regions[0];
            } else {
                dispatch({type: fetchSelectedRegionDataTypes.success, result: []});
                return null;
            }
        });
    };

export const fetchSelectedRegionById =
    (meta: IRPRequestMeta, id: number) =>
    async (dispatch: Dispatch, getState: () => IRPStore): Promise<ISelectedRegion> => {
        const query = {regionId: id};

        const currentRegions = getState().offerList.selectedRegions;

        if (isEqual(getState().offerList.selectedRegionsLatestQuery, query)) {
            // region didn't change, return reference from store
            dispatch({type: fetchSelectedRegionDataTypes.success, result: currentRegions});
            return currentRegions[0];
        }
        dispatch({type: fetchSelectedRegionDataTypes.start, latestQuery: query});

        const regionDetailApiLink = apiV2Link.region.detail(Scenario.REGION_DETAIL, query);
        return getRequest(meta, regionDetailApiLink).then((response: ISelectedRegion): ISelectedRegion => {
            dispatch({type: fetchSelectedRegionDataTypes.success, result: [response]});
            return response;
        });
    };

/**
 * Multi region list
 */
interface IMultiRegionListResponse {
    count: number;
    page_size: number;
    results: IMultiSelectedRegion[];
}

const regionListApiLink = apiV2ListLink.region.list(Scenario.REGION_LIST);

export const fetchSelectedMultiRegionsByIds =
    (meta: IRPRequestMeta, regions: number[]) =>
    async (dispatch: Dispatch, getState: () => IRPStore): Promise<IMultiSelectedRegion[]> => {
        dispatch({type: fetchSelectedRegionDataTypes.start, latestQuery: regions});

        const currentRegions = getState().offerList.selectedRegions;
        const regionsIdArray = currentRegions.map((r) => r.id);

        if (isEqual([...regionsIdArray].sort(), [...regions].sort())) {
            // regions didn't change, return reference from store
            dispatch({type: fetchSelectedRegionDataTypes.success, result: currentRegions});
            return currentRegions;
        }

        const url = appendQueryString(regionListApiLink, {region: regions});
        return getRequest(meta, url).then((response: IMultiRegionListResponse) => {
            dispatch({type: fetchSelectedRegionDataTypes.success, result: response.results});
            return response.results;
        });
    };

/**
 /**
 * Reset selected regions
 */
export const RESET_FETCH_SELECTED_REGIONS = "offer_list/RESET_FETCH_SELECTED_REGIONS";
export const resetFetchSelectedRegions = () => ({type: RESET_FETCH_SELECTED_REGIONS});

export const resetFetchSelectedRegionsAtRoute =
    () =>
    async (dispatch: Dispatch): Promise<boolean> => {
        dispatch({type: RESET_FETCH_SELECTED_REGIONS});
        return true;
    };

/**
 * Fetch at route
 */
export const fetchSelectedRegionsAtRoute =
    (selectedDataSelector: (state: IRPStore) => ISelectedRegion[]) =>
    ({meta, route}: IFetchContext<IRPRequestMeta>) =>
    async (dispatch: Dispatch, getState: () => IRPStore): Promise<IMultiSelectedRegion[]> => {
        const queryRegion: string | string[] = route.query.region;

        // handle query value - multi region
        if (Array.isArray(queryRegion)) {
            const regionIds: number[] = queryRegion.map((r) => parseInt(r, 10)).filter((id) => Number.isFinite(id));
            return dispatch(fetchSelectedMultiRegionsByIds(meta, regionIds));
        }
        // handle query value - single region
        const regionId = parseInt(queryRegion, 10);
        const selectedRegions = selectedDataSelector(getState());
        if (Number.isFinite(regionId) && (isEmpty(selectedRegions) || selectedRegions[0].id !== regionId)) {
            return [await dispatch(fetchSelectedRegionById(meta, regionId))];
        }
        // pass when query value has no region e.g. friendly url
        return [];
    };
