import {Dispatch} from "redux";

import {loadGoogleMapsApi} from "@pg-mono/google-api";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {createDebouncedAction, createDebouncedFunction} from "../../store/utils/create_debounced_action";

export interface AutocompletePrediction {
    description: string;
    matched_substrings: PredictionSubstring[];
    place_id: string;
    reference?: string;
    structured_formatting: AutocompleteStructuredFormatting;
    terms: PredictionTerm[];
    types: string[];
}

interface AutocompleteStructuredFormatting {
    main_text: string;
    main_text_matched_substrings: PredictionSubstring[];
    secondary_text: string;
}

interface PredictionSubstring {
    length: number;
    offset: number;
}

interface PredictionTerm {
    offset: number;
    value: string;
}

enum PlacesServiceStatus {
    INVALID_REQUEST = "INVALID_REQUEST",
    NOT_FOUND = "NOT_FOUND",
    OK = "OK",
    OVER_QUERY_LIMIT = "OVER_QUERY_LIMIT",
    REQUEST_DENIED = "REQUEST_DENIED",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    ZERO_RESULTS = "ZERO_RESULTS"
}

const FETCH_PLACE_LIST = "offer/FETCH_PLACE_LIST";
export const fetchPlaceListTypes = createRequestActionTypes(FETCH_PLACE_LIST);

const searchGooglePlaces = async (input: string): Promise<AutocompletePrediction[]> => {
    await loadGoogleMapsApi(["places"]);

    return new Promise((resolve, reject) => {
        const service = new google.maps.places.AutocompleteService();

        service.getPlacePredictions(
            {input, componentRestrictions: {country: "pl"}},
            (predictions: AutocompletePrediction[] | null, status: PlacesServiceStatus) => {
                if ([PlacesServiceStatus.ZERO_RESULTS, PlacesServiceStatus.OK].includes(status)) {
                    return resolve(predictions || []);
                }

                return reject(status);
            }
        );
    });
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const fetchPlaceListSimple = (input: string, mapPredictions?: <T>(prediction: AutocompletePrediction) => T) => (dispatch: Dispatch) => {
    dispatch({type: fetchPlaceListTypes.start});

    return new Promise((resolve) => {
        searchGooglePlaces(input).then((predictions) => {
            const result = {places: predictions};
            dispatch({type: fetchPlaceListTypes.success, result});

            return resolve(predictions);
        });
    });
};

export const {action: fetchPlaceList, clear: stopFetchPlaceList} = createDebouncedAction(fetchPlaceListSimple, 500);
export const resetPlaceList = () => ({type: fetchPlaceListTypes.reset});

// Because of google places api prices we want to keep the number of calls at minimum.
export const optimizedFetchPlaceList = (() => {
    let latestSearchInput = "";
    return (searchInput: string) => async (dispatch: Dispatch) => {
        if (latestSearchInput !== searchInput) {
            latestSearchInput = searchInput;
            return dispatch(fetchPlaceList(searchInput));
        }
    };
})();

const optimizedSearchGooglePlaces = (input: string, initialValue: {value: string; label: string}) => {
    const hasValue = initialValue.value && initialValue.label;

    // Because Google Maps API is expensive make request only when `input` is longer or equal 3 characters
    if ((input && input.length >= 3) || hasValue) {
        return searchGooglePlaces(input || initialValue.label).then((predictions) => {
            return Promise.resolve(predictions.map((prediction) => ({label: prediction.description, value: prediction.place_id})));
        });
    }

    return Promise.resolve([]);
};

export const {action: fetchDebouncedSearchGooglePlaces} = createDebouncedFunction(optimizedSearchGooglePlaces, 500);
