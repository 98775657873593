import React from "react";

import {ApplicationSourceSection} from "../../application/utils/ApplicationSource";
import {IOfferBoxOffer, OfferBox} from "../components/offer_box/OfferBox";
import {useOfferDetailsActions} from "../hooks/use_offer_details_actions";
import {IModalPropertyListOffer} from "../list/components/OfferListPropertiesModal";

interface IProps {
    offer: IOfferBoxWithPropertiesModalOffer;
    unlinkVendor?: boolean;
    sourceSection?: ApplicationSourceSection;
    mainPage?: boolean;
    shouldLinkToOffer?: boolean;
    onClick?: () => void;
    index?: number;
}

export type IOfferBoxWithPropertiesModalOffer = IOfferBoxOffer & IModalPropertyListOffer;

export function OfferBoxWithOfferDetailsAction(props: IProps) {
    const {unlinkVendor, mainPage} = props;

    const {showOfferDetailsGeneralHandler} = useOfferDetailsActions();

    return (
        <OfferBox
            offer={props.offer}
            onShowOfferDetailsBtnClick={showOfferDetailsGeneralHandler}
            offerDetailsBtnText={props.offer.configuration.pre_sale ? "Zobacz inwestycję" : undefined}
            unlinkVendor={unlinkVendor}
            disableFavInfoText={mainPage}
            shouldLinkToOffer={props.shouldLinkToOffer}
            onClick={props.onClick}
            index={props.index}
        />
    );
}
