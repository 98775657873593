import {useCallback} from "react";
import {useSelector} from "react-redux";

import {IRPStore} from "../../app/rp_reducer";
import {useResponsiveLinkTarget} from "../../hooks/use_responsive_link_target";
import {useAppDispatch} from "../../store/hooks";
import {useUserPreferencesFilters} from "../../user/hooks/use_user_preferences_filters";
import {IOfferGroupsSimplified} from "../detail/types/IOfferGroups";
import {createOfferLink} from "../helpers/create_offer_link";
import {showOfferModal as showOfferDetailsModalAction} from "../state/offer_modals_slice";
import {IOfferModalName} from "../types/OfferModalName";

const modalName: IOfferModalName = "mainOfferModal";

export const useOfferDetailsActions = () => {
    const dispatch = useAppDispatch();
    const userPreferencesFilters = useUserPreferencesFilters();
    const searchFormValues = useSelector((state: IRPStore) => state.search.formValues);
    const target = useResponsiveLinkTarget();

    const showOfferDetailsModal = useCallback(
        (offer: {id: number}) => {
            dispatch(
                showOfferDetailsModalAction({
                    modalName,
                    offerId: offer.id,
                    filters: {
                        rooms: {
                            lower: userPreferencesFilters.rooms_0 ? +userPreferencesFilters.rooms_0 : "",
                            upper: userPreferencesFilters.rooms_1 ? +userPreferencesFilters.rooms_1 : ""
                        },
                        area: {
                            lower: userPreferencesFilters.area_0 ? userPreferencesFilters.area_0 : "",
                            upper: userPreferencesFilters.area_1 ? userPreferencesFilters.area_1 : ""
                        },
                        floor_choices: userPreferencesFilters.floor_choices?.map(Number) || [],
                        price: {
                            lower: searchFormValues.price.lower || "",
                            upper: searchFormValues.price.upper || ""
                        }
                    }
                })
            );
        },
        [searchFormValues, userPreferencesFilters]
    );

    const showOfferDetailsGeneralHandler = useCallback(
        (offer: {id: number; slug: string; configuration: {pre_sale?: boolean}; vendor: {slug: string}; groups: IOfferGroupsSimplified | null}) => {
            if (offer.configuration.pre_sale) {
                const link = createOfferLink(offer);

                if (target === "_blank") {
                    window.open(link);
                } else {
                    window.location.href = link;
                }
            } else {
                showOfferDetailsModal(offer);
            }
        },
        [showOfferDetailsModal]
    );

    return {
        showOfferDetailsModal,
        showOfferDetailsGeneralHandler
    };
};
