import {OfferType} from "../../offer/types/OfferType";
import {IPropertyEstimatedPrice} from "../actions/fetch_property_estimated_price";

// Map of all cities for which we have price stats
export const cityWithEstimatedPriceStats = new Map([
    [46083, "gdansk"],
    [11158, "krakow"],
    [16187, "lodz"],
    [30892, "poznan"],
    [8647, "warszawa"],
    [13220, "lublin"],
    [32657, "szczecin"],
    [26804, "olsztyn"],
    [9692, "bialystok"],
    [48806, "zielona-gora"],
    [22550, "bydgoszcz"],
    [31600, "katowice"],
    [36190, "kielce"],
    [33659, "rzeszow"],
    [26955, "wroclaw"]
]);

// Map of all districts for which we have price stats
export const districtsWithEstimatedPriceStats = new Map([
    // Gdańsk
    [52202, {city: "gdansk", district: "brzezno"}],
    [52114, {city: "gdansk", district: "chelm-i-gdansk-poludnie"}],
    [52205, {city: "gdansk", district: "jasien"}],
    [52148, {city: "gdansk", district: "kokoszki"}],
    [52477, {city: "gdansk", district: "letnica"}],
    [52478, {city: "gdansk", district: "mlyniska"}],
    [52479, {city: "gdansk", district: "nowy-port"}],
    [52181, {city: "gdansk", district: "oliwa"}],
    [52480, {city: "gdansk", district: "olszynka"}],
    [52115, {city: "gdansk", district: "osowa"}],
    [52131, {city: "gdansk", district: "piecki-migowo"}],
    [52177, {city: "gdansk", district: "przymorze-wielkie"}],
    [52192, {city: "gdansk", district: "siedlce"}],
    [52166, {city: "gdansk", district: "srodmiescie"}],
    [52303, {city: "gdansk", district: "stogi"}],
    [52484, {city: "gdansk", district: "ujescisko-lostowice"}],
    [52170, {city: "gdansk", district: "wrzeszcz"}],
    [52486, {city: "gdansk", district: "wyspa-sobieszewska"}],
    // Katowice
    [52143, {city: "katowice", district: "zespol-dzielnic-polnocnych"}],
    [52144, {city: "katowice", district: "zespol-dzielnic-poludniowych"}],
    [52161, {city: "katowice", district: "zespol-dzielnic-srodmiejskich"}],
    [52475, {city: "katowice", district: "zespol-dzielnic-wschodnich"}],
    [52152, {city: "katowice", district: "zespol-dzielnic-zachodnich"}],
    // Kraków
    [52201, {city: "krakow", district: "bienczyce"}],
    [52137, {city: "krakow", district: "biezanow-prokocim"}],
    [52127, {city: "krakow", district: "bronowice"}],
    [52138, {city: "krakow", district: "czyzyny"}],
    [52124, {city: "krakow", district: "debniki"}],
    [52175, {city: "krakow", district: "grzegorzki"}],
    [52123, {city: "krakow", district: "krowodrza"}],
    [52150, {city: "krakow", district: "lagiewniki-borek-falecki"}],
    [52141, {city: "krakow", district: "mistrzejowice"}],
    [52142, {city: "krakow", district: "podgorze"}],
    [52139, {city: "krakow", district: "podgorze-duchackie"}],
    [52112, {city: "krakow", district: "pradnik-bialy"}],
    [52130, {city: "krakow", district: "pradnik-czerwony"}],
    [52111, {city: "krakow", district: "stare-miasto"}],
    [52171, {city: "krakow", district: "swoszowice"}],
    [52120, {city: "krakow", district: "wzgorza-krzeslawickie"}],
    [52117, {city: "krakow", district: "zwierzyniec"}],
    // Łódź
    [52133, {city: "lodz", district: "baluty"}],
    [52167, {city: "lodz", district: "gorna"}],
    [52164, {city: "lodz", district: "polesie"}],
    [52174, {city: "lodz", district: "srodmiescie"}],
    [52149, {city: "lodz", district: "widzew"}],
    // Poznań
    [52118, {city: "poznan", district: "grunwald"}],
    [52119, {city: "poznan", district: "jezyce"}],
    [52128, {city: "poznan", district: "nowe-miasto"}],
    [52116, {city: "poznan", district: "stare-miasto"}],
    [52159, {city: "poznan", district: "wilda"}],
    // Szczecin
    [52147, {city: "szczecin", district: "polnoc"}],
    [52140, {city: "szczecin", district: "prawobrzeze"}],
    [52158, {city: "szczecin", district: "srodmiescie"}],
    [52136, {city: "szczecin", district: "zachod"}],
    // Warszawa
    [38720, {city: "warszawa", district: "bemowo"}],
    [42663, {city: "warszawa", district: "bialoleka"}],
    [28904, {city: "warszawa", district: "bielany"}],
    [20284, {city: "warszawa", district: "mokotow"}],
    [38687, {city: "warszawa", district: "ochota"}],
    [36957, {city: "warszawa", district: "praga-polnoc"}],
    [51145, {city: "warszawa", district: "praga-poludnie"}],
    [22883, {city: "warszawa", district: "rembertow"}],
    [50052, {city: "warszawa", district: "srodmiescie"}],
    [32953, {city: "warszawa", district: "targowek"}],
    [24305, {city: "warszawa", district: "ursus"}],
    [3766, {city: "warszawa", district: "ursynow"}],
    [30707, {city: "warszawa", district: "wawer"}],
    [37544, {city: "warszawa", district: "wesola"}],
    [1320, {city: "warszawa", district: "wilanow"}],
    [3645, {city: "warszawa", district: "wlochy"}],
    [32638, {city: "warszawa", district: "wola"}],
    [36021, {city: "warszawa", district: "zoliborz"}],
    // Wrocław
    [52122, {city: "wroclaw", district: "fabryczna"}],
    [52121, {city: "wroclaw", district: "krzyki"}],
    [52156, {city: "wroclaw", district: "psie-pole"}],
    [52113, {city: "wroclaw", district: "srodmiescie"}],
    [52129, {city: "wroclaw", district: "stare-miasto"}]
]);

export type ApiFieldToEstimatePriceType = keyof Omit<IPropertyEstimatedPrice, "date" | "slug_city" | "city">;

export const DEFAULT_API_FIELD_TO_ESTIMATE_PRICE = "avg_price_m2";

/**
 * Returns API field key based on passed argument
 * @param type {OfferType} type of displayed offer
 * @param area {number} property area
 * @returns {keyof IPropertyEstimatedPrice} API field key
 */
export const getApiFieldToEstimatePrice = (type: OfferType, area: number): ApiFieldToEstimatePriceType => {
    if (type === OfferType.HOUSE) {
        return "avg_price_m2_house";
    }

    if (type === OfferType.FLAT) {
        switch (true) {
            case area <= 30:
                return "avg_price_m2_area_to_30m2";
            case area <= 50:
                return "avg_price_m2_area_between_30_and_50m2";
            case area <= 70:
                return "avg_price_m2_area_between_50_and_70m2";
            case area <= 100:
                return "avg_price_m2_area_between_70_and_100m2";
            case area <= 120:
                return "avg_price_m2_area_between_100_and_120m2";
            case area > 120:
                return "avg_price_m2_area_above_120m2";
            default:
                throw new Error("API field for flat not found");
        }
    }

    return DEFAULT_API_FIELD_TO_ESTIMATE_PRICE;
};

/**
 * Returns price change (across some amount of time) expressed in percentages
 * @param startPrice {number} price at the beginning
 * @param endPrice {number} price at the end
 * @returns {number} percentages price change
 */
export const getPricePercentageChange = (startPrice: number, endPrice: number): number => {
    return parseFloat((((endPrice - startPrice) / startPrice) * 100).toFixed(2));
};

/**
 * Returns expected price range
 * @param area {number} property area value
 * @param avgPrice {number} average price value of property type
 * @returns {[number, number]} calculated estimated price range for given property area. Average price value multiplied
 * by the area +/- 10 percentages.
 */
export const getTotalPriceRange = (area: number, avgPrice: number): [number, number] => {
    const avgTotalPrice = area * avgPrice;

    const tenPercentagesOfPrice = getPercentageOfPrice(avgTotalPrice, 10);

    return [roundPriceToNearestTen(avgTotalPrice - tenPercentagesOfPrice), roundPriceToNearestTen(avgTotalPrice + tenPercentagesOfPrice)];
};

/**
 * Returns percentage value of given price
 * @param price {number} price value
 * @param percentages {number} percentages values
 * @returns {number} calculated percentage value of given price
 */
export const getPercentageOfPrice = (price: number, percentages: number): number => {
    return (percentages / 100) * price;
};

/**
 * Returns price value rounded to the nearest `10`
 * @param price {number} price value
 * @returns {number} rounded - to the nearest `10` - price value
 */
export const roundPriceToNearestTen = (price: number): number => {
    return Math.ceil(price / 10) * 10;
};
