import {Dispatch} from "redux";

import {catch404} from "@pg-mono/request";
import {createRequestActionTypes} from "@pg-mono/request-state";

import {fetchApplicationPropertyDetailById} from "../api/fetch_application_property_detail_by_id";
import {IApplicationProperty} from "../types/IApplicationProperty";

const APPLICATION_PROPERTY_DETAIL_PREFIX = "desktop/application/property_detail/fetch";
export const fetchApplicationPropertyDetailTypes = createRequestActionTypes(APPLICATION_PROPERTY_DETAIL_PREFIX);

export const fetchApplicationPropertyDetail =
    (propertyId: number, applicationId: string) =>
    (dispatch: Dispatch): Promise<IApplicationProperty | void> => {
        dispatch({type: fetchApplicationPropertyDetailTypes.start, id: applicationId});

        return fetchApplicationPropertyDetailById(propertyId)
            .then((json) => {
                dispatch({type: fetchApplicationPropertyDetailTypes.success, result: json, id: applicationId});
                return json;
            })
            .catch(
                catch404(() => {
                    dispatch({type: fetchApplicationPropertyDetailTypes.error, id: applicationId});
                })
            );
    };

export const resetApplicationPropertyDetail = () => ({type: fetchApplicationPropertyDetailTypes.reset});
